<template>
  <el-form :form="form"
           :rules="rules"
           :label-width="label_width">
    <el-col v-for="(item,index) in forms"
            :key="index"
            :lg="5"
            :md="12"
            :sm="12"
            :xl="5">
    </el-col>
  </el-form>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    forms: {
      type: Array,
      default: []
    }
  },
  methods: {

  }
}
</script>

<style>
</style>